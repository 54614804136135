import Image from "components/image";
// import { useStaticQuery, graphql } from "gatsby";
import Layout from "components/layout";
import LinkButton from "components/link_button";
import SEO from "components/seo";
import Slideshow from "components/slideshow";
import { Link } from "gatsby";
import privacyMark from "images/privacy_mark.png";
import React from "react";
import Style from "styles/about.module.scss";

const About = () => (
  <Layout>
    <SEO title="About" path="about" />
    <section className={Style.aboutHeading}>
      <div className={Style.aboutHeading__inner}>
        <h1 className={Style.aboutHeading__title}>ABOUT</h1>
      </div>
    </section>
    <section className={Style.aboutIntro}>
      <div className={Style.aboutIntro__inner}>
        <div className={Style.aboutIntro__wrapper}>
          <div className={Style.aboutIntro__headWrapper}>
            <h2 className={Style.aboutIntro__head}>
              社員の成長を一番に考え
              <br />
              ワクワクする未来を共に創造する
            </h2>
          </div>
          <div className={Style.aboutIntro__contentWrapper}>
            <div className={Style.aboutIntro__content}>
              <p>
                「自社開発」と「取引先業務」この2つの事業を軸とすることで、インプットとアウトプットの機会が増え、社員が成長しやすい環境が整っています。
              </p>
              <p>
                取引先での業務を通じて知りえた技術やノウハウを自社開発にも応用したり、その逆も経験できることで相乗効果が生まれ、会社と社員双方の成長をより一層加速させることができるのです。
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={Style.aboutAdvantage}>
      <h2 className={Style.aboutAdvantage__title}>Advantage</h2>
      <div className={Style.aboutAdvantage__slideshow}>
        <Slideshow />
      </div>
      <div className={Style.aboutAdvantage__comachiBanner}>
        <Link to="/comachicart/">
          <Image filename="bnr_advantage_comachi.png" />
        </Link>
      </div>
      <div className={Style.aboutAdvantage__linkButton}>
        <LinkButton color="black" text="MORE" url="/about/advantage/" />
      </div>
    </section>

    <section className={Style.aboutCompanyProfile}>
      <h2 className={Style.aboutCompanyProfile__title}>Company Profile</h2>
      <table className={Style.aboutCompanyProfileTable}>
        <tbody className={Style.aboutCompanyProfileTable__tbody}>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>社名</th>
            <td className={Style.aboutCompanyProfileTable__content}>株式会社イーゼ</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>所在地</th>
            <td className={Style.aboutCompanyProfileTable__content}>
              〒101-0052
              <br />
              東京都千代田区神田小川町3-6-1
              <br />
              栄信ビル7階
            </td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>電話番号</th>
            <td className={Style.aboutCompanyProfileTable__content}>03-5244-5955</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>資本金</th>
            <td className={Style.aboutCompanyProfileTable__content}>1,500万円</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>設立</th>
            <td className={Style.aboutCompanyProfileTable__content}>2010年4月</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>従業員数</th>
            <td className={Style.aboutCompanyProfileTable__content}>50名（2024年3月末現在）</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>代表取締役</th>
            <td className={Style.aboutCompanyProfileTable__content}>石井　幸治</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>顧問弁護士</th>
            <td className={Style.aboutCompanyProfileTable__content}>
              ほくと総合法律事務所
              <a style={{ textDecoration: "underline" }} href="https://www.hslo.jp/" target="_blank">
                https://www.hslo.jp/
              </a>
            </td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>
              インボイス適格請求書
              <br />
              発行事業番号
            </th>
            <td className={Style.aboutCompanyProfileTable__content}>T4-0400-0104-1606</td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>認定資格</th>
            <td className={Style.aboutCompanyProfileTable__content}>
              <div>Pマーク</div>
              <div>
                <img src={privacyMark} style={{ width: "100px" }} /> <br />
              </div>
              <div>労働者派遣事業許可番号 派13-314252</div>
            </td>
          </tr>
          <tr className={Style.aboutCompanyProfileTable__item}>
            <th className={Style.aboutCompanyProfileTable__head}>商標登録</th>
            <td className={Style.aboutCompanyProfileTable__content}>
              <div>商標　　：　comachicart</div>
              <div>登録番号：　登録第5538384号</div>
              <div>商標権者：　株式会社イーゼ</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={Style.aboutCompanyProfile__mapWrapper}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d405.0329122962612!2d139.7623162433535!3d35.69513778771541!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c107414aef3%3A0x9a734fb3b8416ef2!2z44CSMTAxLTAwNTIg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy656We55Sw5bCP5bed55S677yT5LiB55uu77yW4oiS77yRIOaghOS_oeODk-ODqw!5e0!3m2!1sja!2sjp!4v1618965925320!5m2!1sja!2sjp"
          className={Style.aboutCompanyProfile__map}
          title="map"
        ></iframe>
      </div>
    </section>

    <section className={Style.aboutHistory}>
      <h2 className={Style.aboutHistory__title}>History</h2>
      <div className={Style.aboutHistory__content}>
        <table className={Style.aboutHistoryTable}>
          <tbody className={Style.aboutHistoryTable__tbody}>
            <tr className={Style.aboutHistoryTable__item}>
              <th className={Style.aboutHistoryTable__head}>2024.4</th>
              <td className={Style.aboutHistoryTable__content}>第15期目　開始</td>
            </tr>
            <tr className={Style.aboutHistoryTable__item}>
              <th className={Style.aboutHistoryTable__head}>2023.4</th>
              <td className={Style.aboutHistoryTable__content}>第14期目　開始</td>
            </tr>
            <tr className={Style.aboutHistoryTable__item}>
              <th className={Style.aboutHistoryTable__head}>2022.10</th>
              <td className={Style.aboutHistoryTable__content}>comachicartの本格販売開始</td>
            </tr>
            <tr className={Style.aboutHistoryTable__item}>
              <th className={Style.aboutHistoryTable__head}>2021.4 </th>
              <td className={Style.aboutHistoryTable__content}>神田本社を千代田区神田司町から神田小川町に移転</td>
            </tr>
            <tr className={Style.aboutHistoryTable__item}>
              <th className={Style.aboutHistoryTable__head}>2010.4</th>
              <td className={Style.aboutHistoryTable__content}>株式会社イーゼ設立</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </Layout>
);

export default About;

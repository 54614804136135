import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'styles/components/slideshow.css';

import Image from 'components/image';
import {Link} from 'gatsby';
import React from 'react';
import Slider from 'react-slick';

const Slideshow = () => {
  const settings = {
    dots: true,
    centerMode: true,
    initialSlide: 1,  // 真ん中のスライドを最初に表示
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    centerPadding: '150px',  // デバイス幅960px以上に適応
    responsive: [
      // {
      //   // デバイス幅560pxまで適用
      //   breakpoint: 560,
      //   settings: {
      //     centerPadding: "0px",
      //   },
      // },
      {
        // デバイス幅760pxまで適用
        breakpoint: 760,
        settings: {
          centerPadding: '0px',
        },
      },
      {
        // デバイス幅960pxまで適用
        breakpoint: 960,
        settings: {
          centerPadding: '100px',
        },
      },
    ],
  };
  return (
      <div className = 'slideShow'><Slider{...settings}>
      <div className = 'eachSlide'><div className = 'eachSlide__inner'>
      <Link to = '/about/advantage/#product' className = 'imgWrapper'>
      <Image filename = 'about_advantage_product2.jpg' className = 'img dark' />
      <h2 className = 'slideTitle'>Product</h2>
            </Link>
      </div>
          <div className="txtArea">
            <p className="slideTxt">
              メインのSES、受託事業以外に自社システムの開発も行っています。
              ECサイトのショッピングカートシステム「comachicart(コマチカート)」を軸に、社内で運用中の勤怠・有給管理システムなどを設計から運用まで自社の社員だけで行っています。
            </p>
      </div>
        </div><div className = 'eachSlide'>
      <div className = 'eachSlide__inner'>
      <Link to = '/about/advantage/#workshop' className = 'imgWrapper'>
      <Image filename = 'about_advantage_workshop2.png' className =
           'img dark' />
      <h2 className = 'slideTitle'>Workshop</h2>
            </Link>
      </div>
          <div className="txtArea">
            <p className="slideTxt">
              月に一度少人数のグループに分かれてワークショップを開催しています。
              そこでは、普段の業務では携わらない分野も含め、各々が学びたいことを持ち寄って共に学び、より効率的にスキルを伸ばしていきます。
            </p>
      </div>
        </div><div className = 'eachSlide'>
      <div className = 'eachSlide__inner'>
      <Link to = '/about/advantage/#mbo' className = 'imgWrapper'>
      <Image filename = 'about_advantage_mbo2.png' className = 'img dark' />
      <h2 className = 'slideTitle'>Mbo</h2>
            </Link>
      </div>
          <div className="txtArea">
            <p className="slideTxt">
              社員、組織それぞれがが成長していくために目標管理制度を設けています。
              半年ごとに目標設定の機会を設け、自己実現と組織への貢献を軸に、個々の能力に見合った課題を設定します。
            </p>
      </div>
        </div></Slider>
    </div>);
};

export default Slideshow;
